import { request } from "../request";

// 登录
export function login(data) {
    return request({
        method: "post",
        url: "/xjtu/auth/login",
        data,
    });
}

// 退出登录
export function loginOut(data) {
    return request({
        method: "post",
        url: "/xjtu/auth/loginOut",
        data,
    });
}
