<template>
    <div class="box_content">
        <div class="ConDivContent_box">
            <div class="base-container">
                <div class="ConDivContent_bg">
                    <div class="ConDivContent_cn">
                        <div class="title">账号登录</div>
                        <el-form
                                :model="loginForm"
                                :rules="loginFormRules"
                                ref="loginFormRef"
                                class="demo-ruleForm"
                        >
                            <el-form-item prop="username">
                                <img class="img_l"
                                     src="../../assets/images/username.png"
                                     alt=""
                                />
                                <el-input
                                        v-model="loginForm.username"
                                        placeholder="身份证/港澳通行证/台胞证/国内护照号码"
                                        maxlength="20"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <img class="img_l"
                                     src="../../assets/images/password.png"
                                     alt=""
                                />
                                <el-input
                                        v-model="loginForm.password"
                                        :type="isShowPwd ? 'password' : 'text'"
                                        maxlength="20"
                                        placeholder="密码"
                                ></el-input>
                                <img @click="changePwdStatus()"
                                     v-if="isShowPwd"
                                     class="img_r"
                                     src="../../assets/images/eye01.png"
                                     alt=""
                                />
                                <img @click="changePwdStatus()"
                                      v-if="!isShowPwd"
                                      class="img_r"
                                      src="../../assets/images/eye02.png"
                                      alt=""
                                />
                            </el-form-item>

                            <el-form-item class="btn-box">
                                <el-button @click="submitForm()">登录</el-button>
                            </el-form-item>
                            <div class="box">
                                为保证安全流畅填报信息，推荐使用谷歌、火狐浏览器。如果使用360、搜狗浏览器，请切换至极速模式。
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
        <div class="content base-container">
            <div class="floatL">
                <h3>网站介绍</h3>
                <ul>
                    <li>本系统用于西安交通大学2024年强基计划初试的考点城市选择、报名缴费。</li>
                    <li>考生需使用西安交通大学分配的账号、密码登录本系统。</li>
                    <li>考生在系统中检查个人信息，并填写考试城市志愿。校方将会根据你的志愿情况及生源省份安排考位。</li>
                    <li>完成考试城市选择和缴费后，考生须于2024年6月3日00:00后登录本网站自行下载打印准考证。</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import {login} from "r/index/login.js";

    export default {
        name: "Login",
        data() {
            return {
                isShowPwd: true, // 控制密码显示隐藏
                loginForm: {
                    username: "",
                    password: "",
                },
                // 表单验证规则对象
                loginFormRules: {
                    // 对用户名进行校验
                    username: [
                        {required: true, message: "请输入账号", trigger: "blur"},
                    ],
                    // 对密码进行校验
                    password: [
                        {required: true, message: "请输入密码", trigger: "blur"},
                        {min: 6, max: 20, message: "请输入正确的密码", trigger: "blur"},
                    ],
                },
            };
        },
        created() {
        },
        methods: {
            // 改变密码显示状态
            changePwdStatus() {
                this.isShowPwd = !this.isShowPwd;
            },
            // 表单验证
            submitForm() {
                if (
                    this.loginForm.username.slice(17, 18) == "x" ||
                    this.loginForm.username.slice(17, 18) == "X"
                ) {
                    this.loginForm.username = this.loginForm.username.slice(0, 17) + "X";
                }
                this.$refs.loginFormRef.validate(async (valid) => {
                    // 验证用户名和密码是否符合规定
                    if (!valid) return
                    // 发送请求的操作
                    let {data: res} = await login(this.loginForm);
                    // console.log(res, "返回用户注册数据");
                    // 根据返回的状态码做想关的判断
                    if (res.code !== 200) {
                        return this.$message.error(res.message);
                    }

                    this.$store.commit('setToken',res.data.token)
                    this.$store.commit('setAccountInfo',res.data.account)
                    await this.$router.replace("/main/index");
                    this.$message.success(res.message);
                });
            },
        },
    };
</script>


<style scoped lang="scss">
    @import "../../assets/scss/base.scss";

    // 内容区域
    .box_content {
        text-align: left;

        .ConDivContent_box {
            height: 450px;
            background: url("../../assets/images/bg_pic.png") no-repeat center center;
            background-size: 100% 100%;
            position: relative;
            overflow: hidden;
        }

        @media screen and (min-width: 1601px) {
            .ConDivContent_box {
                height: 650px;
                max-height: 650px;
            }
        }

        .content {
            margin: 40px auto 0;
            /*padding: 0 50px;*/

            .floatL {
                padding: 0 20px;
                h3 {
                    font-size: 20px;
                    color: #4c4c4c;
                    font-weight: inherit;
                    padding-bottom: 15px;
                    margin: 0 0 23px 0;
                    border-bottom: 4px solid #e0823d;
                }

                ul {
                    padding: 0;
                    margin:0;
                    li {
                        line-height: 24px;
                        margin-bottom: 23px;
                        font-size: 14px;
                        list-style: none;
                    }
                }
            }
        }
    }

    .ConDivContent_bg {
        width: 340px;
        height: 395px;
        position: absolute;
        top: 80px;
        right: 20px;
        margin-top: 0;
        background: url("../../assets/images/loginbgp.png") 0 center no-repeat;

        .ConDivContent_cn {
            padding: 40px 20px 20px;

            .title {
                color: #4c4c4c;
                font-size: 20px;
                font-weight: inherit;
                margin-bottom: 40px
            }

            .el-form {
                .el-form-item {
                    position: relative;
                    margin-bottom: 30px;

                    &.btn-box {
                        margin: 0;
                    }

                    img.img_l {
                        position: absolute;
                        left: 25px;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1000;
                    }

                    img.img_r {
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translate(0, -50%);
                        z-index: 1000;
                        cursor: pointer;
                    }

                    ::v-deep .el-input__inner {
                        height: 50px;
                        line-height: 50px;
                        padding: 0 10px 0 50px;
                        border-radius: 0;
                        color: #4d4d4d;
                        border: 2px solid #d9d9d9;

                        &:focus {
                            border-color: var(--color);
                        }
                    }

                    ::v-deep .el-form-item__error {
                        color: #e0823d;
                    }

                    .el-button {
                        width: 300px;
                        height: 50px;
                        line-height: 50px;
                        text-align: center;
                        background: var(--color);
                        color: #fff;
                        font-size: 16px;
                        border-radius: 6px;
                        padding: 0;
                        margin-bottom: 10px;

                        &.is-disabled,
                        &.is-disabled:focus,
                        &.is-disabled:hover {
                            color: rgba(225, 225, 225, .6);
                            cursor: not-allowed;
                            background-image: none;
                            background-color: rgba(86, 50, 121, .6);
                            border-color: #EBEEF5;
                        }
                    }
                }

                .box {
                    font-size: 12px;
                    line-height: 20px;
                    color: #e0823d;
                }
            }
        }
    }

    @media screen and (max-width: 1600px) {
        .ConDivContent_bg {
            width: 340px;
            height: 395px;
            position: absolute;
            top: 25px;
            right: 20px;
            margin-top: 0;
            background: url("../../assets/images/loginbgp.png") 0 center no-repeat;
        }
    }

    @media screen and (max-width: 1400px) {
        .ConDivContent_bg {
            width: 340px;
            height: 395px;
            position: absolute;
            top: 25px;
            right: 20px;
            margin-top: 0;
            background: url("../../assets/images/loginbgp.png") 0 center no-repeat;
        }
    }

</style>
